/* Custom transition classes for react-transition-group */
.fade-enter {
    opacity: 0;
  }
  .fade-enter-active {
    opacity: 1;
    transition: opacity 500ms;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
    transition: opacity 500ms;
  }
  
  .badge {
    @apply bg-red-600 text-white text-xs font-bold px-2 py-1 rounded;
    top: 0.5rem;
    right: 0.5rem;
  }