

/* .bdy{
    background: #2e364a;
} */

.timeline{
    margin: 50px auto;
    position: relative;
    max-width: 1200px;
    /* margin: 100px auto; */
}

.contner{
    width: 100%;
    padding-left: 80px;
    padding-right: 25px;
    /* padding: 10px 50px; */
    position: relative;
    width: 100%;
    /* background: rgba(0,0,0,0.3); */
    animation: movedown  linear forwards;
    opacity: 0;
    z-index: 20;
     
}

@keyframes movedown {
    0%{
        opacity: 1;
        transform: translateY(-30px);
    }
    100%{
        opacity: 1;
        transform: translateY(0px);
    }
}


.contner:nth-child(1){
    animation-delay: 0s;
}

.contner:nth-child(2){
    animation-delay: 1s;
}
.contner:nth-child(3){
    animation-delay: 1s;
}
.contner:nth-child(4){
    animation-delay: 1s;
}

.contner:nth-child(5){
    animation-delay: 1s;
}


.text-box{
    padding:20px 30px ;
    background: #ffffff;
    position: relative;
    border-radius: 6px;
    font-size: 15px;
}

.left-contner{
    left:0;
}

.right-contner{
    left:0;
    /* left:50% */
}

.contner .img{
    position: absolute;
    width: 40px;
    border-radius: 2%;
    /* right: -20px; */
    left: 10px;
    top: 32px;
    z-index: 10;
}
.right-contner .img{
    left: 10px;
    /* left: -20px; */
}

.timeline::after{
    content: '';
    position: absolute;
    width: 6px;
    height: 100%;
    background:#f44c4c;
    top: 0;
    left: 31px;
    /* left: 50%; */
    margin-left: -3px;
    z-index: 0;
    animation: moveline 4s linear forwards;
   
}

@keyframes moveline {
    0%{
        height: 0;
    }
    100%{
        height: 100%;
    }
}


.left-contner-arrow{
    height: 0;
    width: 0;
    position: absolute;
    top: 28px;
    z-index: 1;
    border-right: 15px solid #fff;
        border-left: 0;
        left:-15px;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    /* border-left: 15px solid #fff; */
    /* right: -15px; */
    
}


.right-contner-arrow{
    height: 0;
    width: 0;
    position: absolute;
    top: 28px;
    z-index: 1;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid #fff;
    left: -15px;
}

@media screen and (max-width:600px) {
    
    .timeline{
        margin: 50px auto;
    }

    .timeline::after{
        left: 31px;
    }
    .contner{
        width: 100%;
        padding-left: 80px;
        padding-right: 25px;
    }

    .text-box small{
        margin-bottom: 10px;
    }
    .right-contner{
        left:0;
    }
    .left-contner .img, .right-contner .img{
        left: 10px;
    }

    .left-contner-arrow, .right-contner-arrow{
        border-right: 15px solid #fff;
        border-left: 0;
        left:-15px;
    }



}